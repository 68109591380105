import React from "react"


function SvgComponent(props) {

  var hanrei1_color = props.frontmatter.hanrei1_color


  var hanrei_width = "0 0 960 650"

  return (
    <div>
<svg viewBox={hanrei_width} id="svg2" version="1.1">
  <defs id="defs6">
    <clipPath id="clipPath18" clipPathUnits="userSpaceOnUse">
      <path style={{clipRule: 'evenodd'}} id="path16" d="M 0,1.2207e-4 H 960 V 540.00012 H 0 Z" />
    </clipPath>
    <clipPath id="clipPath28" clipPathUnits="userSpaceOnUse">
      <path style={{clipRule: 'evenodd'}} id="path26" d="M 1.4305e-5,0 H 960.00001 V 540 H 1.4305e-5 Z" />
    </clipPath>

    <clipPath id="clipPath40" clipPathUnits="userSpaceOnUse">
      <path style={{clipRule: 'evenodd'}} id="path38" d="M 1.4305e-5,0 H 960.00001 V 540 H 1.4305e-5 Z" />
    </clipPath>
    <clipPath id="clipPath52" clipPathUnits="userSpaceOnUse">
      <path style={{clipRule: 'evenodd'}} id="path50" d="M 1.4305e-5,0 H 960.00001 V 540 H 1.4305e-5 Z" />
    </clipPath>
    <clipPath id="clipPath64" clipPathUnits="userSpaceOnUse">
      <path style={{clipRule: 'evenodd'}} id="path62" d="M 1.4305e-5,0 H 960.00001 V 540 H 1.4305e-5 Z" />
    </clipPath>
    <clipPath id="clipPath76" clipPathUnits="userSpaceOnUse">
      <path style={{clipRule: 'evenodd'}} id="path74" d="M 1.4305e-5,0 H 960.00001 V 540 H 1.4305e-5 Z" />
    </clipPath>
    <clipPath id="clipPath148" clipPathUnits="userSpaceOnUse">
      <path style={{clipRule: 'evenodd'}} id="path146" d="M 1.4305e-5,0 H 960.00001 V 540 H 1.4305e-5 Z" />
    </clipPath>
    <clipPath id="clipPath160" clipPathUnits="userSpaceOnUse">
      <path style={{clipRule: 'evenodd'}} id="path158" d="M 1.4305e-5,0 H 960.00001 V 540 H 1.4305e-5 Z" />
    </clipPath>
    <clipPath id="clipPath172" clipPathUnits="userSpaceOnUse">
      <path style={{clipRule: 'evenodd'}} id="path170" d="M 1.4305e-5,0 H 960.00001 V 540 H 1.4305e-5 Z" />
    </clipPath>
    <clipPath id="clipPath184" clipPathUnits="userSpaceOnUse">
      <path style={{clipRule: 'evenodd'}} id="path182" d="M 1.4305e-5,0 H 960.00001 V 540 H 1.4305e-5 Z" />
    </clipPath>
    <clipPath id="clipPath196" clipPathUnits="userSpaceOnUse">
      <path style={{clipRule: 'evenodd'}} id="path194" d="M 1.4305e-5,0 H 960.00001 V 540 H 1.4305e-5 Z" />
    </clipPath>
  </defs>
  <g transform="matrix(1.3333333,0,0,-1.3333333,0,720)" id="g10">
    <g id="g12">
      <g clipPath="url(#clipPath18)" id="g14">
        <path id="path20" style={{fill: '#ffffff', fillOpacity: 1, fillRule: 'evenodd', stroke: 'none'}} d="M 0,6.1035e-5 H 960 V 540.00006 H 0 Z" />
      </g>
    </g>

{/* 高さ凡例 */}
    <g id="g22">
      <g clipPath="url(#clipPath28)" id="g24">
        <text id="text32" style={{fontVariant: 'normal', fontWeight: 'normal', fontSize: 24, fontFamily: 'MS', InkscapeFontSpecification: 'MS-Gothic', writingMode: 'lr-tb', fill: '#000000', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none'}} transform="matrix(1,0,0,-1,71.2,491.69)">
          <tspan id="tspan30" y={0} x="0 36.023998 72.047997">高さ：</tspan>
        </text>
      </g>
    </g>
    <g id="g34">
      <g clipPath="url(#clipPath40)" id="g36">
        <text id="text44" style={{fontVariant: 'normal', fontWeight: 'normal', fontSize: 24, fontFamily: 'MSReferenceSansSerif', InkscapeFontSpecification: 'MSReferenceSansSerif', writingMode: 'lr-tb', fill: '#000000', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none'}} transform="matrix(1,0,0,-1,169.22,491.69)">
          <tspan id="tspan42">{props.frontmatter.hanrei_height_name}</tspan>
        </text>
      </g>
    </g>

{/* 色凡例 */}
    <g id="g46">
      <g clipPath="url(#clipPath52)" id="g48">
        <text id="text56" style={{fontVariant: 'normal', fontWeight: 'normal', fontSize: 24, fontFamily: 'MS', InkscapeFontSpecification: 'MS-Gothic', writingMode: 'lr-tb', fill: '#000000', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none'}} transform="matrix(1,0,0,-1,70.264,440.04)">
          <tspan id="tspan54" y={0} x={0}>色</tspan>
        </text>
      </g>
    </g>
    <g id="g58">
      <g clipPath="url(#clipPath64)" id="g60">
        <text id="text68" style={{fontVariant: 'normal', fontWeight: 'normal', fontSize: 24, fontFamily: 'MS', InkscapeFontSpecification: 'MS-Gothic', writingMode: 'lr-tb', fill: '#000000', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none'}} transform="matrix(1,0,0,-1,142.29,440.04)">
          <tspan id="tspan66" y={0} x={0}>：</tspan>
        </text>
      </g>
    </g>
    <g id="g70">
      <g clipPath="url(#clipPath76)" id="g72">
        <text id="text80" style={{fontVariant: 'normal', fontWeight: 'normal', fontSize: 24, fontFamily: 'MSReferenceSansSerif', InkscapeFontSpecification: 'MSReferenceSansSerif', writingMode: 'lr-tb', fill: '#000000', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none'}} transform="matrix(1,0,0,-1,168.29,440.04)">
          <tspan id="tspan78">{props.frontmatter.hanrei_color_name}</tspan>
        </text>
      </g>
    </g>


{/* 棒グラフ */}
{/* 1の表 */}
    <path id="path90" style={{fill: props.frontmatter.hanrei1_color, fillOpacity: 1, fillRule: 'evenodd', stroke: 'none'}} d="m 118.8,146.76 h 64.35 V 392.19 H 118.8 Z" />
{/* 1の側面と上面 */}
    <path id="path92" style={{fill: props.frontmatter.hanrei1_color, fillOpacity: 1, fillRule: 'evenodd', stroke: 'none'}} d="m 183.15,392.19 21.45,21.45 V 168.21 l -21.45,-21.45 z" />
    <path id="path94" style={{fill: props.frontmatter.hanrei1_color, fillOpacity: 1, fillRule: 'evenodd', stroke: 'none'}} d="m 118.8,392.19 21.45,21.45 h 64.35 l -21.45,-21.45 z" />
{/* 1の側面と上面の色調整 */}
    <path id="path9992" style={{fill: 'black', fillOpacity: 0.2, fillRule: 'evenodd', stroke: 'none', zIndex:5}} d="m 183.15,392.19 21.45,21.45 V 168.21 l -21.45,-21.45 z" />
    <path id="path9994" style={{fill: 'white', fillOpacity: 0.2, fillRule: 'evenodd', stroke: 'none', zIndex:5}} d="m 118.8,392.19 21.45,21.45 h 64.35 l -21.45,-21.45 z" />
{/* 1の外周線 */}
    <path id="path96" style={{fill: 'none', stroke: '#ffffff', strokeWidth: '0.95999998', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, strokeDasharray: 'none', strokeOpacity: 1}} d="m 118.8,392.19 21.45,21.45 H 204.6 V 168.21 L 183.15,146.76 H 118.8 Z m 0,0 h 64.35 l 21.45,21.45 M 183.15,392.19 V 146.76" />

{/* 棒グラフ */}
{/* 2の表 */}
    <path id="path82" style={{fill: props.frontmatter.hanrei2_color, fillOpacity: 1, fillRule: 'evenodd', stroke: 'none'}} d="m 204.6,168.36 h 64.26 v 176.1 H 204.6 Z" />
{/* 2の側面と上面 */}
    <path id="path84" style={{fill: props.frontmatter.hanrei2_color, fillOpacity: 1, fillRule: 'evenodd', stroke: 'none'}} d="m 268.86,344.46 21.42,21.42 v -176.1 l -21.42,-21.42 z" />
    <path id="path86" style={{fill: props.frontmatter.hanrei2_color, fillOpacity: 1, fillRule: 'evenodd', stroke: 'none'}} d="m 204.6,344.46 21.42,21.42 h 64.26 l -21.42,-21.42 z" />
{/* 2の側面と上面の色調整 */}
    <path id="path84" style={{fill: 'black', fillOpacity: 0.2, fillRule: 'evenodd', stroke: 'none', zIndex:5}} d="m 268.86,344.46 21.42,21.42 v -176.1 l -21.42,-21.42 z" />
    <path id="path86" style={{fill: 'white', fillOpacity: 0.2, fillRule: 'evenodd', stroke: 'none', zIndex:5}} d="m 204.6,344.46 21.42,21.42 h 64.26 l -21.42,-21.42 z" />
{/* 2の外周線 */}
    <path id="path88" style={{fill: 'none', stroke: '#ffffff', strokeWidth: '0.95999998', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, strokeDasharray: 'none', strokeOpacity: 1}} d="m 204.6,344.46 21.42,21.42 h 64.26 V 189.78 L 268.86,168.36 H 204.6 Z m 0,0 h 64.26 l 21.42,21.42 m -21.42,-21.42 v -176.1" />

{/* 棒グラフ */}
{/* 3の表 */}
    <path id="path98" style={{fill: props.frontmatter.hanrei3_color, fillOpacity: 1, fillRule: 'evenodd', stroke: 'none'}} d="m 290.28,189 h 64.26 v 110.94 h -64.26 z" />

    <path id="path100" style={{fill: props.frontmatter.hanrei3_color, fillOpacity: 1, fillRule: 'evenodd', stroke: 'none'}} d="m 354.54,299.94 21.42,21.42 V 210.42 L 354.54,189 Z" />
    <path id="path102" style={{fill: props.frontmatter.hanrei3_color, fillOpacity: 1, fillRule: 'evenodd', stroke: 'none'}} d="m 290.28,299.94 21.42,21.42 h 64.26 l -21.42,-21.42 z" />

    <path id="path100" style={{fill: "black", fillOpacity: 0.2, fillRule: 'evenodd', stroke: 'none', zIndex:5}} d="m 354.54,299.94 21.42,21.42 V 210.42 L 354.54,189 Z" />
    <path id="path102" style={{fill: "white", fillOpacity: 0.2, fillRule: 'evenodd', stroke: 'none', zIndex:5}} d="m 290.28,299.94 21.42,21.42 h 64.26 l -21.42,-21.42 z" />

    <path id="path104" style={{fill: 'none', stroke: '#ffffff', strokeWidth: '0.95999998', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, strokeDasharray: 'none', strokeOpacity: 1}} d="m 290.28,299.94 21.42,21.42 h 64.26 V 210.42 L 354.54,189 h -64.26 z m 0,0 h 64.26 l 21.42,21.42 M 354.54,299.94 V 189" />

{/* 棒グラフ */}
{/* 4の表 */}
    <path id="path106" style={{fill: props.frontmatter.hanrei4_color, fillOpacity: 1, fillRule: 'evenodd', stroke: 'none'}} d="M 375.96,211.32 H 441 v 62.28 h -65.04 z" />

    <path id="path108" style={{fill: props.frontmatter.hanrei4_color, fillOpacity: 1, fillRule: 'evenodd', stroke: 'none'}} d="m 441,273.6 20.76,20.76 V 232.08 L 441,211.32 Z" />
    <path id="path110" style={{fill: props.frontmatter.hanrei4_color, fillOpacity: 1, fillRule: 'evenodd', stroke: 'none'}} d="m 375.96,273.6 20.76,20.76 h 65.04 L 441,273.6 Z" />

    <path id="path108" style={{fill: "black", fillOpacity: 0.2, fillRule: 'evenodd', stroke: 'none', zIndex:5}} d="m 441,273.6 20.76,20.76 V 232.08 L 441,211.32 Z" />
    <path id="path110" style={{fill: "white", fillOpacity: 0.2, fillRule: 'evenodd', stroke: 'none', zIndex:5}} d="m 375.96,273.6 20.76,20.76 h 65.04 L 441,273.6 Z" />

    <path id="path112" style={{fill: 'none', stroke: '#ffffff', strokeWidth: '0.95999998', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, strokeDasharray: 'none', strokeOpacity: 1}} d="m 375.96,273.6 20.76,20.76 h 65.04 V 232.08 L 441,211.32 h -65.04 z m 0,0 H 441 l 20.76,20.76 M 441,273.6 v -62.28" />
    <path id="path114" style={{fill: 'none', stroke: '#4472c4', strokeWidth: '0.47999999', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, strokeDasharray: 'none', strokeOpacity: 1}} d="m 269.76,169.68 204.33,-2.04" />
    <path id="path116" style={{fill: 'none', stroke: '#4472c4', strokeWidth: '0.47999999', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, strokeDasharray: 'none', strokeOpacity: 1}} d="M 355.68,189.72 490.14,189.3" />
    <path id="path118" style={{fill: 'none', stroke: '#4472c4', strokeWidth: '0.47999999', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, strokeDasharray: 'none', strokeOpacity: 1}} d="m 308.76,140.08 45.71,49.16" />
    <path id="path120" style={{fill: 'none', stroke: '#4472c4', strokeWidth: '0.47999999', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, strokeDasharray: 'none', strokeOpacity: 1}} d="m 374.64,140.04 92.14,98.16" />
    <path id="path122" style={{fill: 'none', stroke: '#4472c4', strokeWidth: '0.47999999', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, strokeDasharray: 'none', strokeOpacity: 1}} d="m 240,140.11 28.75,28.25" />
    <path id="path124" style={{fill: 'none', stroke: '#4472c4', strokeWidth: '0.47999999', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, strokeDasharray: 'none', strokeOpacity: 1}} d="m 440.04,140.04 83.47,98.16" />
    <path id="path126" style={{fill: 'none', stroke: '#4472c4', strokeWidth: '0.47999999', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, strokeDasharray: 'none', strokeOpacity: 1}} d="m 441.12,211.92 65.84,-0.51" />
    <path id="path128" style={{fill: 'none', stroke: '#4472c4', strokeWidth: '0.47999999', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, strokeDasharray: 'none', strokeOpacity: 1}} d="m 461.76,232.08 h 61.75" />
    <path id="path130" style={{fill: 'none', stroke: '#000000', strokeWidth: '2.75999999', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, strokeDasharray: 'none', strokeOpacity: 1}} d="m 113.34,146.94 c -11.17,0 -20.22,1.51 -20.22,3.37 V 316.9 c 0,1.87 -9.053,3.37 -20.22,3.37 11.167,0 20.22,1.51 20.22,3.37 v 82.01 c 0,1.86 9.05,3.37 20.22,3.37" />
    <path id="path132" style={{fill: 'none', stroke: '#000000', strokeWidth: '2.51999998', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, strokeDasharray: 'none', strokeOpacity: 1}} d="m 54.06,505.86 h -18 V 320.25 h 36.889 v 1.16" />

{/* 下線 */}
    <path id="path134" style={{fill: 'none', stroke: props.frontmatter.hanrei1_color, strokeWidth: '2', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, strokeDasharray: 'none', strokeOpacity: 1}} d="M 204,390 H 400" />
    <path id="path136" style={{fill: 'none', stroke: props.frontmatter.hanrei2_color, strokeWidth: '2', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, strokeDasharray: 'none', strokeOpacity: 1}} d="M 290,345 H 460" />
    <path id="path138" style={{fill: 'none', stroke: props.frontmatter.hanrei3_color, strokeWidth: '2', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, strokeDasharray: 'none', strokeOpacity: 1}} d="M 376,300 H 520" />
    <path id="path140" style={{fill: 'none', stroke: props.frontmatter.hanrei4_color, strokeWidth: '2', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, strokeDasharray: 'none', strokeOpacity: 1}} d="M 461,255 H 580" />

{/* 色の名前 */}
    <g id="g142">
      <g clipPath="url(#clipPath148)" id="g144">
        <text id="text152" style={{fontVariant: 'normal', fontWeight: 'normal', fontSize: 24, fontFamily: 'MSReferenceSansSerif', InkscapeFontSpecification: 'MSReferenceSansSerif', writingMode: 'lr-tb', fill: '#000000', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none'}} transform="matrix(1,0,0,-1,320,395)">
          <tspan id="tspan150">{props.frontmatter.kokudo_hanrei1_name}</tspan>
        </text>
      </g>
    </g>
    <g id="g154">
      <g clipPath="url(#clipPath160)" id="g156">
        <text id="text164" style={{fontVariant: 'normal', fontWeight: 'normal', fontSize: 24, fontFamily: 'MSReferenceSansSerif', InkscapeFontSpecification: 'MSReferenceSansSerif', writingMode: 'lr-tb', fill: '#000000', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none'}} transform="matrix(1,0,0,-1,380,350)">
          <tspan id="tspan162">{props.frontmatter.kokudo_hanrei2_name}</tspan>
        </text>
      </g>
    </g>
    <g id="g166">
      <g clipPath="url(#clipPath172)" id="g168">
        <text id="text176" style={{fontVariant: 'normal', fontWeight: 'normal', fontSize: 24, fontFamily: 'MSReferenceSansSerif', InkscapeFontSpecification: 'MSReferenceSansSerif', writingMode: 'lr-tb', fill: '#000000', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none'}} transform="matrix(1,0,0,-1,440,305)">
          <tspan id="tspan174">{props.frontmatter.kokudo_hanrei3_name}</tspan>
        </text>
      </g>
    </g>
    <g id="g178">
      <g clipPath="url(#clipPath184)" id="g180">
        <text id="text188" style={{fontVariant: 'normal', fontWeight: 'normal', fontSize: 24, fontFamily: 'MSReferenceSansSerif', InkscapeFontSpecification: 'MSReferenceSansSerif', writingMode: 'lr-tb', fill: '#000000', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none'}} transform="matrix(1,0,0,-1,500,260)">
          <tspan id="tspan186">{props.frontmatter.kokudo_hanrei4_name}</tspan>
        </text>
      </g>
    </g>
    <g id="g190">
      <g clipPath="url(#clipPath196)" id="g192">
        <text id="text200" style={{fontVariant: 'normal', fontWeight: 'normal', fontSize: 24, fontFamily: 'MSReferenceSansSerif', InkscapeFontSpecification: 'MSReferenceSansSerif', writingMode: 'lr-tb', fill: '#000000', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none'}} transform="matrix(1,0,0,-1,487.27,154.46)">
          <tspan id="tspan198">{props.frontmatter.kokudo_height_ratio}</tspan>
        </text>
      </g>
    </g>
    <path id="path202" style={{fill: 'none', stroke: '#4472c4', strokeWidth: '0.47999999', strokeLinecap: 'butt', strokeLinejoin: 'miter', strokeMiterlimit: 10, strokeDasharray: 'none', strokeOpacity: 1}} d="m 182.04,148.08 271.6,-2.83" />
  </g>


    <text x="90" y="580" style={{fontVariant: 'normal', fontWeight: 'normal', fontSize: 28, fontFamily: 'MS', InkscapeFontSpecification: 'MS-Gothic', writingMode: 'lr-tb', fill: '#000000', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none'}}>
      年次：{props.frontmatter.year_display}
    </text>
 

    <text x="90" y="630" style={{fontVariant: 'normal', fontWeight: 'normal', fontSize: 28, fontFamily: 'MS', InkscapeFontSpecification: 'MS-Gothic', writingMode: 'lr-tb', fill: '#000000', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none'}}>
      出典：{props.frontmatter.height_origin}（高さ）、{props.frontmatter.color_origin}（色）
    </text>
 

</svg>
    </div>
  )
}

export default SvgComponent

