import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Hanreiimg from '../../components/hanrei-img3';

// import { Link } from 'gatsby';
// import Styles from './index.module.css';



// const Table = props => (
// <div>
//     <div className={Styles.user}>
//       <div className={Styles.description}>
//         <h2 className={Styles.username}><span className={Styles.themename}>{props.themename}</span>{props.username}</h2>
//         <p className={Styles.excerpt}>{props.excerpt}</p>
//       </div>
//     </div>
//     <Link to={props.url}>
//     </Link>
// </div>
// )




// const useStateWithLocalStorage = localStorageKey => {
//   const [value, setValue] = React.useState(
//     typeof window !== 'undefined' && window.localStorage.getItem(localStorageKey)
//     || ''
//   );
 
//   React.useEffect(() => {
//     if (typeof window !== 'undefined') {
//       localStorage.setItem(localStorageKey, value);
//     }
//   }, [value]);
 
//   return [value, setValue];
// };

 
const About: React.FunctionComponent<AboutProps> = (props) => {

  // const [value, setValue] = useStateWithLocalStorage(
  //   'cityset1'
  // );
 
  // const onChange = event => setValue(event.target.value);
 
  return (
  <div >
        <Hanreiimg
          frontmatter={props.frontmatter}
        />
    </div>

  );
};
 
export default About;
