//2次メッシュ表示
import React from 'react';
import {Link} from 'gatsby'
import {Viewer,UrlTemplateImageryProvider,Credit, HeadingPitchRange, Rectangle, ImagerySplitDirection, ScreenSpaceEventHandler,ScreenSpaceEventType, Math, Cartesian3, Transforms, Color, Entity } from '../../../node_modules/cesium';
import '../../../node_modules/cesium/Source/Widgets/widgets.css';
import CesiumNavigation from "cesium-navigation-es6";
import {
  buttonshitakml,
  buttononoffkml,
  canvaswrap,
  buttonshita, 
  breadcrumb,
  breadcrumb2,
  buttonarea,
  buttonareanew,
  buttonareanew2,
  buttonareakinoubutton,
  buttonshitakml2,
  buttononoffkml2,
  buttononoffkml3,
  canvaswrap2,
  slider,
  radiobutton,
  buttononoff,
  } from "./cesium50.module.css";
import Cityset1 from "../citysetting/citytitle10_d"
// import { Entity } from '../../../node_modules/cesium';

var cityset;

class CesiumContainer extends React.Component {
  divRef = React.createRef();
  viewer = null;
  entity = null;

  constructor(props) {
    super(props);
    this.state = {
      data: this.props.year1,
      name: "React"
    }
    this.handleInputChange = this.handleInputChange.bind(this);
  }



  handleInputChange(event){
    const target = event.target;
    const name = target.name;
    this.setState({
      data: target.value
    });
  }



  render() {
    return (
//       <div style={{marginTop:"0"}}>
// <div style={{display:"flex"}}>
// <div className={cityset}>

<div style={{position:"relative", marginTop:this.props.margintop, marginBottom:this.props.marginbottom, marginLeft:this.props.marginleft}}>
        <div style={{position:"absolute", display:"flex", zIndex:"10", width:"90%",
        //  justifyContent:"space-between",
         marginTop:this.props.margintopbartop, marginBottom:this.props.margintopbarbottom, marginLeft:this.props.margintopbarleft, width:this.props.topbarwidth}}>
          <div style={{width:"70vw", margin:"2vh 0 0 1vw"}}>
    <Cityset1
      kotei="国土全体"
      kml_kmz={this.props.kml_kmz}
      no={this.props.no}
     />
  </div>


  <div className={buttonshitakml2} style={{margin:"2vh 0 0 0"}}>
            <div className={buttononoffkml3} type="button" id="kmldownloadbutton" value="GoogleEarthで表示">
              GoogleEarthで表示
            </div>
            <div className={buttononoffkml2}>
              <div>
                <div type="button" id="rotate" value="回転させる">回転させる</div>
              </div>
            </div>
            <div className={buttononoffkml2}>
              <div
                type="button"
                id="googlemapopen" 
                className={buttononoff}
                value="場所を確認する">場所を確認する
              </div>
            </div>
          </div>



{/* <div className={buttonshitakml}>
            <div className={buttononoffkml} type="button" id="kmldownloadbutton" value="GoogleEarthで表示">GoogleEarthで表示</div>
          </div> */}
          </div>


          <div className={canvaswrap2}>
          <div id="cesiumcontainerdiv">
            <div ref={this.divRef} style={{height:this.props.height, width:this.props.width}}>
              <div id="slider" className={slider}>
              </div>
            </div>
          </div>
        </div>


        {/* <div className={canvaswrap}>
          <div id="cesiumcontainerdiv">
            <div ref={this.divRef} style={{height:this.props.height}}>
              <div id="slider" className={slider}></div>
            </div>
          </div>
        </div> */}


        <div id="r"></div>




        <div className={buttonareanew} style={{
  paddingLeft:"1vw", marginTop:this.props.marginbuttontop1,marginLeft:this.props.margintopbarleft
}}>
  <div className={breadcrumb}>

      <div>
        <input className={radiobutton} type="radio" name="yearbutton" id="timesetoff" value="背景だけを表示"/>
        <label htmlFor="timesetoff">消す</label>
      </div>
      <div>
        <input className={radiobutton} type="radio" name="yearbutton" id="timeset1" value={this.props.year1}/>
        <label htmlFor="timeset1">{this.props.year1}　</label>
      </div>
      <div>
        <input className={radiobutton} type="radio" name="yearbutton" id="timeset2" value={this.props.year2}/>
        <label htmlFor="timeset2">{this.props.year2}　</label>
      </div>
      <div>
        <input className={radiobutton} type="radio" name="yearbutton" id="timeset3" value={this.props.year3}/>
        <label htmlFor="timeset3">{this.props.year3}　</label>
      </div>
      <div>
        <input className={radiobutton} type="radio" name="yearbutton" id="timeset4" value={this.props.year4}/>
        <label htmlFor="timeset4">{this.props.year4}　</label>
      </div>
      <div>
        <input className={radiobutton} type="radio" name="yearbutton" id="timeset5" value={this.props.year5}/>
        <label htmlFor="timeset5">{this.props.year5}　</label>
      </div>
      <div>
      <input className={radiobutton} type="radio" name="yearbutton" id="timesetauto" value="auto"/>
        <label htmlFor="timesetauto">連続　</label>
      </div>
  </div>
</div>

{/* 
        <div style={{display:"flex",justifyContent: "space-between"}}>

          <div className={buttonshita}>
            <input className={radiobutton} type="radio" name="yearbutton" id="timeset1" value={this.props.year1}/>
            <label className={buttononoff} htmlFor="timeset1">{this.props.year1}</label>
          </div>

          <div className={buttonshita}>
            <input className={radiobutton} type="radio" name="yearbutton" id="timeset2" value={this.props.year2}/>
            <label className={buttononoff} htmlFor="timeset2">{this.props.year2}</label>
          </div>

          <div className={buttonshita}>
          <input className={radiobutton} type="radio" name="yearbutton" id="timeset3" value={this.props.year3}/>
            <label className={buttononoff} htmlFor="timeset3">{this.props.year3}</label>
          </div>

          <div className={buttonshita}>
          <input className={radiobutton} type="radio" name="yearbutton" id="timeset4" value={this.props.year4}/>
            <label className={buttononoff} htmlFor="timeset4">{this.props.year4}</label>
          </div>

          <div className={buttonshita}>
          <input className={radiobutton} type="radio" name="yearbutton" id="timeset5" value={this.props.year5}/>
            <label className={buttononoff} htmlFor="timeset5">{this.props.year5}</label>
          </div>

        </div> */}


{/* 
        <div style={{display:"flex",justifyContent: "space-between"}}>

          <div className={buttonshita}>
            <div className={buttononoff} type="button" id="originview" value="視点を戻す">視点を戻す</div>
          </div>

          <div className={buttonshita}>
          <div className={buttononoff} type="button" id="rotate" value="回転させる">回転させる</div>
          </div>

          <div className={buttonshita}>
            <Link
              className={buttononoff}
              to={this.props.full_url}
              // to={"/"+this.props.no+"/kokudof"}
              kml_kml={this.props.kml_kml}
              kml_kmz={this.props.kml_kmz}
              // dir="/kml/sample.kml"
              width="100%"
              height="70vh"
              no={this.props.no}
            >
            {this.props.full_name}
            </Link>
          </div>

          <div className={buttonshita}>
            <div type="button" id="googlemapopen"  className={buttononoff} value="場所を確認する">場所を確認する</div>
          </div>

          <div className={buttonshita}>
          <input className={radiobutton} type="radio" name="yearbutton" id="timesetoff" value="背景だけを表示"/>
            <label className={buttononoff} htmlFor="timesetoff">背景だけを表示</label>
          </div>

        </div> */}
      </div>
    );
  }




  componentDidMount() {

    // var clock = new Clock({
    //     startTime : JulianDate.fromIso8601('1985-08-30T11:56:04+08'),
    //     stopTime : JulianDate.fromIso8601('2035-08-30T11:56:04+08'),
    //     currentTime : JulianDate.fromIso8601('2017-12-30T11:56:04+08'),
    //     multiplier : 1,
    //     shouldAnimate : true, 
    //     clockRange : ClockRange.LOOP_STOP,
    //     clockStep : ClockStep.SYSTEM_CLOCK_MULTIPLIER
    // });

    // var extent = Rectangle.fromDegrees(122, 20, 153, 45);
    // Camera.DEFAULT_VIEW_RECTANGLE = extent;
    // Camera.DEFAULT_VIEW_FACTOR = 0;


    // var element = document.getElementById( "timeset1" ) ;
    // // 選択状態にする
    // element.checked = true ;

    // function func1() {
    //   var fruits = document.getElementsByName("yearbutton");
    //   for(var i = 0; i < fruits.length; i++){
    //     if(fruits[i].checked) {
    //       console.log("選択された値：", fruits[i].value);
    //     }
    //   }
    // }
    // func1();






/// ビューア起動 ///////
const viewer = new Viewer(this.divRef.current, {
  imageryProvider: 
  new UrlTemplateImageryProvider({
    // url: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg',
    url: 'https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png',
    credit: new Credit('地理院タイル', '', 'https://maps.gsi.go.jp/development/ichiran.html')
  }),
  baseLayerPicker: false,
  timeline : false,
  animation : false,
  fullscreenButton: false,
  homeButton: false,
  vrButton: false,
  geocoder:false,
  sceneModePicker:false,
  navigationHelpButton:false,
  infoBox : false,
  selectionIndicator : false,
  shadows : false,
  // clockViewModel: new ClockViewModel(clock)
});
/////// ビューア起動 ///



/// CSVデータ読み込み ///////
/**
 * 3次メッシュ３D棒グラフオブジェクト作成関数
 * @param  <Number>  meshcode3rd (required) 3次メッシュコード
 * @param  <Number>  h (required) 高さ（単位メートル）
 * @param  <string>　　　　color_abgr (required) 　色
 * @param  <string> description_text (required) オブジェクトの説明文
 * @retrun <object> ans_entities rectangleオブジェクト
 **/


var data1995 = viewer.entities.add(new Entity());
var data2000 = viewer.entities.add(new Entity());
var data2005 = viewer.entities.add(new Entity());
var data2010 = viewer.entities.add(new Entity());
var data2015 = viewer.entities.add(new Entity());

var kokudo_min = this.props.kokudo_min;
var kokudo_ratio = this.props.kokudo_ratio;
var kokudo_shikii1 = this.props.kokudo_shikii1;
var kokudo_shikii2 = this.props.kokudo_shikii2;
var kokudo_shikii3 = this.props.kokudo_shikii3;
var hanrei1_color = this.props.hanrei1_color;
var hanrei2_color = this.props.hanrei2_color;
var hanrei3_color = this.props.hanrei3_color;
var hanrei4_color = this.props.hanrei4_color;
var csv = this.props.csv
var csvmode = this.props.csvmode

var min = kokudo_min
var ratio = Number(kokudo_ratio);
var shikii1 = Number(kokudo_shikii1);
var shikii2 = Number(kokudo_shikii2);
var shikii3 = Number(kokudo_shikii3);
// var hanrei1_color = Number(hanrei1_color);
// var hanrei2_color = Number(hanrei2_color);
// var hanrei3_color = Number(hanrei3_color);
// var hanrei4_color = Number(hanrei4_color);

/// /// １.2列目の閾値で色分け /////////////////////////
function mesh3rdRect1( meshcode3rd , h, c, dataname){
  var strMeshcoe = String(meshcode3rd);
  var p = parseInt(strMeshcoe.slice(0,2));
  var u = parseInt(strMeshcoe.slice(2,4));
  var q = parseInt(strMeshcoe.slice(4,5));
  var v = parseInt(strMeshcoe.slice(5,6));
  // var r = parseInt(strMeshcoe.slice(6,7));
  // var w = parseInt(strMeshcoe.slice(7,8));
  var lat = p / 1.5 + q / 12
  //  + r / 120
   ;
  var lon = u + 100 + v * 0.125
  //  + w * 0.0125
   ;
  // console.log(lon +":"+ lat) ;
  var latc = lat + 0.08333333/2
  var lonc = lon + 0.125/2

  var color1;
  if (h >= shikii1) {
    color1 = hanrei1_color;
    // color1 = "#67ADDF";
  } else if (h >= shikii2) {
    color1 = hanrei2_color;
  } else if (h >= shikii3) {
    color1 = hanrei3_color;
  } else {
    color1 = hanrei4_color;
  }



  var ans_entities = {
    parent: dataname,
          // parent: data1995,
          // // id:meshcode3rd ,
          // // description : "<p>"+description_text+"</p>",
          // rectangle : 
          // {
            name : lonc+","+latc,
            rectangle : {
  
              coordinates : Rectangle.fromDegrees( lon , lat , lon+0.125 , lat+0.08333333 ),
              height : 0,
              extrudedHeight : h*ratio,
              material : Color.fromCssColorString(color1),
              // material : Color.fromRgba( color_gbra )
              outline : true,
              outlineColor : "white",
              outlineWidth : 1,
              // shadows : ShadowMode.ENABLED
          }
      };
  return ans_entities ;
}

/// ///////////////////////// １.2列目の閾値で色分け ///
/// /// 2.3列目の値で色分け /////////////////////////

function mesh3rdRect2( meshcode3rd , h, c, dataname){
  var strMeshcoe = String(meshcode3rd);
  var p = parseInt(strMeshcoe.slice(0,2));
  var u = parseInt(strMeshcoe.slice(2,4));
  var q = parseInt(strMeshcoe.slice(4,5));
  var v = parseInt(strMeshcoe.slice(5,6));
  // var r = parseInt(strMeshcoe.slice(6,7));
  // var w = parseInt(strMeshcoe.slice(7,8));
  var lat = p / 1.5 + q / 12
  //  + r / 120
   ;
  var lon = u + 100 + v * 0.125
  //  + w * 0.0125
   ;
  // console.log(lon +":"+ lat) ;

  var latc = lat + 0.08333333/2
  var lonc = lon + 0.125/2


  var color1;
  if (c == 3) {
    color1 = hanrei1_color;
    // color1 = "#67ADDF";
  } else if (c == 2) {
    color1 = hanrei2_color;
  } else if (c == 1) {
    color1 = hanrei3_color;
  } else {
    color1 = hanrei4_color;
  }



  var ans_entities = {
    parent: dataname,
          // parent: data1995,
          // // id:meshcode3rd ,
          // // description : "<p>"+description_text+"</p>",
          // rectangle : 
          // {
            name : lonc+","+latc,
            rectangle : {

              coordinates : Rectangle.fromDegrees( lon , lat , lon+0.125 , lat+0.08333333 ),
              height : 0,
              extrudedHeight : h*ratio,
              material : Color.fromCssColorString(color1),
              // material : Color.fromRgba( color_gbra )
              outline : true,
              outlineColor : "white",
              outlineWidth : 1,
              // shadows : ShadowMode.ENABLED
          }
      };
  return ans_entities ;
}


/// ///////////////////////// 2.3列目の値で色分け ///

/// /// 3.2列目の閾値で色分け /////////////////////////

function mesh3rdRect3( meshcode3rd , h, c, dataname){
  var strMeshcoe = String(meshcode3rd);
  var p = parseInt(strMeshcoe.slice(0,2));
  var u = parseInt(strMeshcoe.slice(2,4));
  var q = parseInt(strMeshcoe.slice(4,5));
  var v = parseInt(strMeshcoe.slice(5,6));
  // var r = parseInt(strMeshcoe.slice(6,7));
  // var w = parseInt(strMeshcoe.slice(7,8));
  var lat = p / 1.5 + q / 12
  //  + r / 120
   ;
  var lon = u + 100 + v * 0.125
  //  + w * 0.0125
   ;
  // console.log(lon +":"+ lat) ;

  var latc = lat + 0.08333333/2
  var lonc = lon + 0.125/2


  var color1;
  if (c >= shikii1) {
    color1 = hanrei1_color;
    // color1 = "#67ADDF";
  } else if (c >= shikii2) {
    color1 = hanrei2_color;
  } else if (c >= shikii3) {
    color1 = hanrei3_color;
  } else {
    color1 = hanrei4_color;
  }




  var ans_entities = {
    parent: dataname,

          // parent: data1995,
          // // id:meshcode3rd ,
          // // description : "<p>"+description_text+"</p>",
          // rectangle : 
          // {
            name : lonc+","+latc,
            rectangle : {
  
              coordinates : Rectangle.fromDegrees( lon , lat , lon+0.125 , lat+0.08333333 ),
              height : 0,
              extrudedHeight : h*ratio,
              material : Color.fromCssColorString(color1),
              // material : Color.fromRgba( color_gbra )
              outline : true,
              outlineColor : "white",
              outlineWidth : 1,
              // shadows : ShadowMode.ENABLED
          }
      };
  return ans_entities ;
}






  /// ///////////////////////// 3.2列目の閾値で色分け ///




// 正しいコード
// <div id="r"></div>
function getCSV(no,year,dataname) {
  var req = new XMLHttpRequest();
  req.open('get', '/csv/kokudo/'+no+'_2次メッシュ'+year+'.csv', true);
  // req.open('get', '/kml/kokudo/'+no+'_2次メッシュ'+year+'.csv', true);
  req.send(null);
  req.onload = function() {
    // setCSV(req.responseText);
    //ここでlocalStorageへ
    var dataArr;
    // var r = document.getElementById('r');
    var tmp = req.responseText.split('\n');
    // console.log(tmp.length);

    // var firstview = viewer.entities.add(getJapan3rdmeshRectangle(tmp[0].substr(0,6),tmp[0].substr(7))).then(
    //   viewer.flyTo(viewer.entities)
    // )
    // .catch(failureCallback);


    if (csvmode == "color_shikii") {

      for(let i = 0, len = tmp.length; i< len ; i++){
        dataArr = tmp[i].split(',');
        var minmin = Number(min);
        if (dataArr[1]>minmin) {
          viewer.entities.add(
            mesh3rdRect3(dataArr[0],dataArr[1],dataArr[2],dataname)
            // {
            //   parent: dataname,
            //   // id:meshcode3rd ,
            //   // description : "<p>"+description_text+"</p>",
            //   rectangle: mesh3rdRect3(dataArr[0],dataArr[1],dataArr[2])
            // }
          );
        // viewer.entities.add(mesh3rdRect1(tmp[i].substr(0,6),tmp[i].substr(7)));
        // if(tmp[0]){
        // }
        }
      }
  
    } else if(shikii1 !== 0){
  
        for(let i = 0, len = tmp.length; i< len ; i++){
          dataArr = tmp[i].split(',');
          var minmin = Number(min);
          if (dataArr[1]>minmin) {
            viewer.entities.add(
              mesh3rdRect1(dataArr[0],dataArr[1],dataArr[2],dataname)
              // {
              //   parent: dataname,
              //   // id:meshcode3rd ,
              //   // description : "<p>"+description_text+"</p>",
              //   rectangle: mesh3rdRect1(dataArr[0],dataArr[1],dataArr[2])
              // }
            );
          // viewer.entities.add(mesh3rdRect1(tmp[i].substr(0,6),tmp[i].substr(7)));
          // if(tmp[0]){
          // }
          }
        }
    
    
  
    } else {
  
      for(let i = 0, len = tmp.length; i< len ; i++){
        dataArr = tmp[i].split(',');
        var minmin = Number(min);
        if (dataArr[1]>minmin) {
          viewer.entities.add(
            mesh3rdRect2(dataArr[0],dataArr[1],dataArr[2],dataname)
            // {
            //   parent: dataname,
            //   // id:meshcode3rd ,
            //   // description : "<p>"+description_text+"</p>",
            //   rectangle: mesh3rdRect2(dataArr[0],dataArr[1],dataArr[2])
            // }
          );
        // viewer.entities.add(mesh3rdRect1(tmp[i].substr(0,6),tmp[i].substr(7)));
        // if(tmp[0]){
        // }
        }
      }
  
    }
  

    viewer.zoomTo(viewer.entities);
    
        // tmp.forEach(x => {
        //   dataArr = x.split(',');
        //   if (dataArr[1]>10000) {
        //     viewer.entities.add(getJapan3rdmeshRectangle(dataArr[0],dataArr[1]));
        //   }
        // });
    
    
    
    
        //ここでCesiumへ
    
        // for(let i = 0, len = data999.length; i< len ; i++){
        // // for(let i in data999){
        //   if(data999[i][1]>10000){
        //     var wyoming = viewer.entities.add(getJapan3rdmeshRectangle(data999[i][0],data999[i][1]));
        //   }
        // }
  };
}



// function setCSV(str) {
//   var data999 = [];
//   var dataArr;
//   // var r = document.getElementById('r');
//   var tmp = str.split('\n');
//   tmp.forEach(x => {
//     dataArr = x.split(',');
//     if (dataArr[0]) {
//       data999.push(dataArr.map(x => x.trim()));
//     }
//   });
//   console.log(data999);
//   // var t = '<dl>';
//   // data.forEach(x => {
//   //   t += `<dt>${x[0]}</dt><dd>価格は${x[1]}円</dd>`;
//   // });
//   // t += '</dl>';
//   // r.innerHTML = t;
//   // return data;
// }
getCSV(this.props.no,this.props.year1, data1995);
getCSV(this.props.no,this.props.year2, data2000);
getCSV(this.props.no,this.props.year3, data2005);
getCSV(this.props.no,this.props.year4, data2010);
getCSV(this.props.no,this.props.year5, data2015);
// getCSV(101,2015, data2015);
data1995.show = false;
data2000.show = false;
data2005.show = false;
data2010.show = false;
data2015.show = true;
document.getElementById('timeset5').checked = true;

// let meshdata = [
//   [50302089, 10000],
//   [50302078, 5000],
//   [50302066, 1000],
//  ]
 
// console.log(meshdata[0]);

// var meshmesh = getJapan3rdmeshRectangle(meshdata[0][0],meshdata[0][1]);
// var meshmesh2 = getJapan3rdmeshRectangle( 50302078 , 5000);
// var meshmesh3 = getJapan3rdmeshRectangle( 50302066 , 5000);

// var wyoming = viewer.entities.add(meshmesh);
// var wyoming = viewer.entities.add(meshmesh2);
// var wyoming = viewer.entities.add(meshmesh3);
// viewer.zoomTo(wyoming);

/////// CSVデータ読み込み ///



/// セシウムのロゴを消す ///////
    viewer._cesiumWidget._creditContainer.style.display = "none";
/////// セシウムのロゴを消す ///

/// スライダー //////////
var layers = viewer.imageryLayers;
var chiriinMap = layers.addImageryProvider(
  new UrlTemplateImageryProvider({
    url: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg',
    // url: 'https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png',
//      url: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/',fileExtension: 'jpg',
    credit: new Credit('地理院タイル', '', 'https://maps.gsi.go.jp/development/ichiran.html')
  }),
);
chiriinMap.splitDirection = ImagerySplitDirection.LEFT;
var slider = document.getElementById("slider");
viewer.scene.imagerySplitPosition = slider.offsetLeft / slider.parentElement.offsetWidth;
var handler = new ScreenSpaceEventHandler(slider);
var moveActive = false;
function move(movement){
  if(!moveActive){
    return;
  }
var relativeOffset = movement.endPosition.x;
var splitPosition = (slider.offsetLeft + relativeOffset) / slider.parentElement.offsetWidth;
slider.style.left = 100.0 * splitPosition + "%";
viewer.scene.imagerySplitPosition = splitPosition;
}
handler.setInputAction(function () {
  moveActive = true;
}, ScreenSpaceEventType.LEFT_DOWN);
handler.setInputAction(function () {
  moveActive = true;
}, ScreenSpaceEventType.PINCH_START);
handler.setInputAction(move, ScreenSpaceEventType.MOUSE_MOVE);
handler.setInputAction(move, ScreenSpaceEventType.PINCH_MOVE);
handler.setInputAction(function () {
  moveActive = false;
}, ScreenSpaceEventType.LEFT_UP);
handler.setInputAction(function () {
  moveActive = false;
}, ScreenSpaceEventType.PINCH_END);
////////// スライダー ///

/// 一定の高さ以下でGoogleMapを開く //////////
var win;
var buttongoogle = document.getElementById('googlemapopen');
buttongoogle.addEventListener('click', function() {
  var cartographic = viewer.camera.positionCartographic;
  if(cartographic.height<3000){
    var lon = Math.toDegrees(cartographic.longitude).toFixed(5);
    var lat = Math.toDegrees(cartographic.latitude).toFixed(5); 
    var url = 'http://maps.google.co.jp/maps?q=' + lat + ',' + lon;
    win = window.open(url ,"gmap" ,'width=800,height=600');
  } else {
    alert("棒グラフをダブルクリックして、地面に近づいたうえで、再度このボタンを押してください。\nなお、地面をダブルクリックすると、視点を戻すことができます。")
  }
}, false);
////////// 一定の高さ以下でGoogleMapを開く ///

/// いつでもGoogleMapを開く（動作OK） //////////
// var win;
// var buttongoogle = document.getElementById('googlemapopen');
// buttongoogle.addEventListener('click', function() {
//   var cartographic = viewer.camera.positionCartographic;
//           var lon = Math.toDegrees(cartographic.longitude).toFixed(5);
//           var lat = Math.toDegrees(cartographic.latitude).toFixed(5); 
//           var url = 'http://maps.google.co.jp/maps?q=' + lat + ',' + lon;
//           win = window.open(url ,"gmap" ,'width=800,height=600');
// }, false);
////////// いつでもGoogleMapを開く（動作OK） ///



/// GoogleMapを開く //////////
// var win;
// var buttongoogle = document.getElementById('googlemapopen');
// buttongoogle.addEventListener('click', function() {
//   var cartographic = viewer.camera.positionCartographic;
//           var lon = Math.toDegrees(cartographic.longitude).toFixed(5);
//           var lat = Math.toDegrees(cartographic.latitude).toFixed(5); 
//           var url = 'http://maps.google.co.jp/maps?q=' + lat + ',' + lon;
//           win = window.open(url ,"gmap" ,'width=800,height=600');
// }, false);
////////// GoogleMapを開く ///

/// timelineをtrueにしたまま画面から消す //////////
    // viewer.timeline.container.style.display = 'none';
    // viewer.timeline.resize();
////////// timelineをtrueにしたまま画面から消す ///

/// 地形へのライティングを使う //////////
// viewer.scene.globe.enableLighting = true;
////////// 地形へのライティングを使う ///


/// ONOFF用のタイムライン延長KML準備 ///////
// var kmlaaa = '<?xml version="1.0" encoding="UTF-8" ?><kml xmlns="http://earth.google.com/kml/2.0"><Placemark><TimeSpan><begin>'+'1985'+'-10-01'+'</begin><end>'+'2035'+'-10-01'+'</end></TimeSpan><styleUrl>#PolyCol3</styleUrl><Polygon><altitudeMode>relativeToGround</altitudeMode><outerBoundaryIs><LinearRing><coordinates></coordinates></LinearRing></outerBoundaryIs></Polygon></Placemark></kml>';
// var urlaaa = URL.createObjectURL(new Blob([kmlaaa]));
/////// ONOFF用のタイムライン延長KML準備 ///

/// KMLデータ準備 ///////
    // var options = {
    //   camera: viewer.scene.camera,
    //   canvas: viewer.scene.canvas,
    // };
    // var dataSource1 = new KmlDataSource.load(this.props.kml_kml,options);
/////// KMLデータ準備 ///

/// KMLデータ読み込み ///////
// viewer.dataSources.add(dataSource1).show = true;
// viewer.zoomTo(viewer.entities
//   // , new HeadingPitchRange(0, -1, 2000000)
//   );
// viewer.dataSources.add(KmlDataSource.load(urlaaa,options));
/////// KMLデータ読み込み ///



/// カメラの高さを取得（動作OK） /////////////
var currentPosition = viewer.camera.positionCartographic;
console.log(currentPosition);
// viewer.camera.setView({
//     destination: Cartesian3.fromDegrees(50.0, 5.0, currentPosition.height)
// });
//////////// カメラの高さを取得（動作OK） ///



// console.log(currentPosition["height"]);
// console.log(currentPosition["latitude"]);
// console.log(currentPosition["longitude"]);

// height: 23507791.30725595
// latitude: 0.6137071328556367
// longitude: -1.439896632895322




/// 回転ボタン //////////
var buttona = document.getElementById('rotate');
buttona.addEventListener('click', function() {
    let currentvalue = document.getElementById('rotate').value;
    if (currentvalue == "回転させる") {
        document.getElementById("rotate").value="Stop"
        viewer.clock.onTick.addEventListener(function(clock) {
          viewer.scene.camera.rotateRight(-0.005);
        });
    } else {
          document.getElementById("rotate").value="回転させる"
          viewer.zoomTo(viewer.entities).then(
              function(){
              var cartographic = viewer.camera.positionCartographic;
              // var center = Cartesian3.fromRadians(33.17241, 132.61065, cartographic["height"]/100000);
              // var center = Cartesian3.fromRadians(cartographic["longitude"], cartographic["latitude"], cartographic["height"]/100000);
              var center = Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude+cartographic.height*1.52849689e-7, 50);
              // var center = Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude+cartographic.height*1.52849689e-7, 100);
              var transform = Transforms.eastNorthUpToFixedFrame(center);
              viewer.scene.camera.lookAtTransform(transform, new HeadingPitchRange(0, -0.5, cartographic.height*1.5));
              viewer.clock.onTick.addEventListener(function(clock) {
                viewer.scene.camera.rotateRight(0.005);
              });
              }
          )
          ;
    }
}, false);
////////// 回転ボタン ///



/// ダブルクリックで、ズームする機能 20210718//////////
var handler = new ScreenSpaceEventHandler(viewer.scene.canvas);
handler.setInputAction(function(e) {
    viewer.trackedEntity = undefined;

    let currentvalue = document.getElementById('rotate').value;
    if (currentvalue == "回転させる") {
        document.getElementById("rotate").value="Stop"
        viewer.clock.onTick.addEventListener(function(clock) {
        viewer.scene.camera.rotateRight(-0.005);
        });
    };


    var picked = viewer.scene.pick(e.position);
    if(picked){



      var pickedchara = String(picked.id._name);
      var pickedarr = pickedchara.split(',');
      var latcn = Number(pickedarr[0])
      var loncn = Number(pickedarr[1])
      viewer.camera.flyTo({
        destination: Cartesian3.fromDegrees(latcn,loncn, 1000.0),
        orientation: {
          heading: Math.toRadians(0),
          pitch: Math.toRadians(-90),
          roll: 0.0,
        },
      });

      // 拡大時にラベル追加（動作OK） ///////////////
    //   var citizensBankPark = viewer.entities.add({
    //     name : 'Citizens Bank Park',
    //     position : Cartesian3.fromDegrees(latcn+0.005, loncn-0.001),
    //     label : {
    //         text : 'Citizens Bank Park',
    //         font : '14pt monospace',
    //         style: LabelStyle.FILL_AND_OUTLINE,
    //         outlineWidth : 2,
    //         verticalOrigin : VerticalOrigin.BOTTOM,
    //         pixelOffset : new Cartesian2(0, -9)
    //     }
    // });
      ///////////////// 拡大時にラベル追加（動作OK） ///



      // 拡大時にHTML追加 ///////////////

          ///////////////// 拡大時にHTML追加 ///





    } else {

      viewer.flyTo(viewer.entities, new HeadingPitchRange(0, -1, 50000));

    }
  }, ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
////////// ダブルクリックで、ズームする機能 ///






// /// 視点を戻すボタン（PINも解除） //////////
//     var buttona = document.getElementById('originview');
//     buttona.addEventListener('click', function() {
//                 viewer.flyTo(viewer.entities, new HeadingPitchRange(0, -1, 50000));
//     }, false);
// ////////// 視点を戻すボタン（PINも解除） ///

/// 視点を戻すボタン（PINも解除） //////////
// var buttona = document.getElementById('originview');
// buttona.addEventListener('click', function() {
//             // viewer.flyTo(dataSource1, new HeadingPitchRange(0, -1, 50000));

//             let currentvalue = document.getElementById('rotate').value;
//             if (currentvalue == "回転させる") {
//                 document.getElementById("rotate").value="Stop"
//                 viewer.clock.onTick.addEventListener(function(clock) {
//                 viewer.scene.camera.rotateRight(-0.005);
//                 });
//             };
//             viewer.flyTo(viewer.entities, new HeadingPitchRange(0, -1, 50000));

//           }, false);
////////// 視点を戻すボタン（PINも解除） ///


// 要素を取得
var yearelements = document.getElementsByName( "yearbutton" ) ;
for ( var a="", i=yearelements.length; i--; ) {
	if ( yearelements[i].checked ) {
		var a = yearelements[i].value ;
		break ;
	}
}



/// 時間セットボタン1 //////////
    var buttontimeset1 = document.getElementById('timeset1');
    buttontimeset1.addEventListener('change', function() {
    if(buttontimeset1.checked){
      data2000.show = false;
      data2005.show = false;
      data2010.show = false;
      data2015.show = false;
      data1995.show = true;
    }
    }, false);
////////// 時間セットボタン1 ///
// /// 時間セットボタン2 //////////
var buttontimeset2 = document.getElementById('timeset2');
buttontimeset2.addEventListener('change', function() {
  if(buttontimeset2.checked){
    data1995.show = false;
    data2005.show = false;
    data2010.show = false;
    data2015.show = false;
    data2000.show = true;
  };
}, false);
// ////////// 時間セットボタン2 ///
// /// 時間セットボタン3 //////////
var buttontimeset3 = document.getElementById('timeset3');
buttontimeset3.addEventListener('change', function() {
  if(buttontimeset3.checked){
    data1995.show = false;
    data2000.show = false;
    data2010.show = false;
    data2015.show = false;
    data2005.show = true;
  };
}, false);
// ////////// 時間セットボタン3 ///
// /// 時間セットボタン4 //////////
var buttontimeset4 = document.getElementById('timeset4');
buttontimeset4.addEventListener('change', function() {
  if(buttontimeset4.checked){
    data1995.show = false;
    data2000.show = false;
    data2015.show = false;
    data2005.show = false;
    data2010.show = true;
  };
}, false);
// ////////// 時間セットボタン4 ///
// /// 時間セットボタン5 //////////
var buttontimeset5 = document.getElementById('timeset5');
buttontimeset5.addEventListener('change', function() {
  if(buttontimeset5.checked){
    data1995.show = false;
    data2000.show = false;
    data2010.show = false;
    data2005.show = false;
    data2015.show = true;
  };
}, false);
// ////////// 時間セットボタン5 ///
// /// 時間セットボタンoff //////////
var buttontimesetoff = document.getElementById('timesetoff');
buttontimesetoff.addEventListener('change', function() {
  if(buttontimesetoff.checked){
    data1995.show = false;
    data2000.show = false;
    data2010.show = false;
    data2005.show = false;
    data2015.show = false;
  };
}, false);
// ////////// 時間セットボタンoff ///

// /// 時間セットボタンauto //////////
var buttontimesetauto = document.getElementById('timesetauto');
buttontimesetauto.addEventListener('change', function() {
  if(buttontimesetauto.checked){
    let promise = new Promise((resolve, reject) => { // #1
      data1995.show = false;
      data2000.show = false;
      data2010.show = false;
      data2005.show = false;
      data2015.show = false;
      document.getElementById('timesetoff').checked = true;
      resolve()
    })
    promise.then(() => { // #2
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          data1995.show = true;
          data2000.show = false;
          data2005.show = false;
          data2010.show = false;
          data2015.show = false;
          document.getElementById('timeset1').checked = true;
          resolve()
        }, 500)
      })
    }).then(() => { // #3
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          data1995.show = false;
          data2000.show = true;
          data2005.show = false;
          data2010.show = false;
          data2015.show = false;
          document.getElementById('timeset2').checked = true;
          resolve()
        }, 500)
      })
    }).then(() => { // #4
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          data1995.show = false;
          data2000.show = false;
          data2005.show = true;
          data2010.show = false;
          data2015.show = false;
          document.getElementById('timeset3').checked = true;
          resolve()
        }, 500)
      })
    }).then(() => { // #4
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          data1995.show = false;
          data2000.show = false;
          data2005.show = false;
          data2010.show = true;
          data2015.show = false;
          document.getElementById('timeset4').checked = true;
          resolve()
        }, 500)
      })
    }).then(() => { // #4
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          data1995.show = false;
          data2000.show = false;
          data2005.show = false;
          data2010.show = false;
          data2015.show = true;
          document.getElementById('timeset5').checked = true;
          resolve()
        }, 500)
      })
    }).then(() => { // #3
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          data1995.show = false;
          data2000.show = false;
          data2005.show = false;
          data2010.show = false;
          data2015.show = false;
          document.getElementById('timesetoff').checked = true;
          resolve()
        }, 500)
      })
    }).then(() => { // #3
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          data1995.show = true;
          data2000.show = false;
          data2005.show = false;
          data2010.show = false;
          data2015.show = false;
          document.getElementById('timeset1').checked = true;
          resolve()
        }, 1000)
      })
    }).then(() => { // #3
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          data1995.show = false;
          data2000.show = true;
          data2005.show = false;
          data2010.show = false;
          data2015.show = false;
          document.getElementById('timeset2').checked = true;
          resolve()
        }, 1000)
      })
    }).then(() => { // #4
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          data1995.show = false;
          data2000.show = false;
          data2005.show = true;
          data2010.show = false;
          data2015.show = false;
          document.getElementById('timeset3').checked = true;
          resolve()
        }, 1000)
      })
    }).then(() => { // #4
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          data1995.show = false;
          data2000.show = false;
          data2005.show = false;
          data2010.show = true;
          data2015.show = false;
          document.getElementById('timeset4').checked = true;
          resolve()
        }, 1000)
      })
    }).then(() => { // #4
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          data1995.show = false;
          data2000.show = false;
          data2005.show = false;
          data2010.show = false;
          data2015.show = true;
          document.getElementById('timeset5').checked = true;
          resolve()
        }, 1000)
      })
    }).then(() => { // #3
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          data1995.show = false;
          data2000.show = false;
          data2005.show = false;
          data2010.show = false;
          data2015.show = false;
          document.getElementById('timesetoff').checked = true;
          resolve()
        }, 1000)
      })
    }).then(() => { // #3
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          data1995.show = true;
          data2000.show = false;
          data2005.show = false;
          data2010.show = false;
          data2015.show = false;
          document.getElementById('timeset1').checked = true;
          resolve()
        }, 500)
      })
    }).then(() => { // #3
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          data1995.show = false;
          data2000.show = true;
          data2005.show = false;
          data2010.show = false;
          data2015.show = false;
          document.getElementById('timeset2').checked = true;
          resolve()
        }, 500)
      })
    }).then(() => { // #4
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          data1995.show = false;
          data2000.show = false;
          data2005.show = true;
          data2010.show = false;
          data2015.show = false;
          document.getElementById('timeset3').checked = true;
          resolve()
        }, 500)
      })
    }).then(() => { // #4
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          data1995.show = false;
          data2000.show = false;
          data2005.show = false;
          data2010.show = true;
          data2015.show = false;
          document.getElementById('timeset4').checked = true;
          resolve()
        }, 500)
      })
    }).then(() => { // #4
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          data1995.show = false;
          data2000.show = false;
          data2005.show = false;
          data2010.show = false;
          data2015.show = true;
          document.getElementById('timeset5').checked = true;
          resolve()
        }, 500)
      })
    }).catch(() => { // エラーハンドリング
      console.error('Something wrong!')
    })


  };
}, false);
// ////////// 時間セットボタンauto ///



/// 時間セットボタン1 //////////
    // var yearset1 = this.props.year1+"-11-30";
    // var buttontimeset1 = document.getElementById('timeset1');
    // buttontimeset1.addEventListener('click', function() {
    //   viewer.clock.currentTime =  JulianDate.fromIso8601(yearset1);
    //   viewer.clock.multiplier = 1;
    //   viewer.clock.shouldAnimate = true; //if it was paused.
    // }, false);
////////// 時間セットボタン1 ///


// /// 時間セットボタン3 //////////
//     var yearset3 = this.props.year3+"-11-30";
//     var buttontimeset3 = document.getElementById('timeset3');
//     buttontimeset3.addEventListener('click', function() {
//       viewer.clock.currentTime =  JulianDate.fromIso8601(yearset3);
//       viewer.clock.multiplier = 1;
//       viewer.clock.shouldAnimate = true //if it was paused.
//     }, false);
// ////////// 時間セットボタン3 ///

// /// 時間セットボタン4 //////////
//     var yearset4 = this.props.year4+"-11-30";
//     var buttontimeset4 = document.getElementById('timeset4');
//     buttontimeset4.addEventListener('click', function() {
//       viewer.clock.currentTime =  JulianDate.fromIso8601(yearset4);
//       viewer.clock.multiplier = 1;
//       viewer.clock.shouldAnimate = true //if it was paused.
//     }, false);
// ////////// 時間セットボタン4 ///

// /// 時間セットボタン5 //////////
//     var yearset5 = this.props.year5+"-11-30";
//     var buttontimeset5 = document.getElementById('timeset5');
//     buttontimeset5.addEventListener('click', function() {
//       viewer.clock.currentTime =  JulianDate.fromIso8601(yearset5);
//       viewer.clock.multiplier = 1;
//       viewer.clock.shouldAnimate = true //if it was paused.
//     }, false);
// ////////// 時間セットボタン5 ///

/// 時間セットボタンoff //////////
    // var buttontimeset = document.getElementById('timesetoff');
    // buttontimeset.addEventListener('click', function() {
    //   viewer.clock.currentTime =  JulianDate.fromIso8601('2022-08-30T11:56:04+08');
    //   viewer.clock.multiplier = 1;
    //   viewer.clock.shouldAnimate = true //if it was paused.
    // }, false);
////////// 時間セットボタンoff ///

/// Navigation /////
var navoptions = {};
navoptions.defaultResetView = Rectangle.fromDegrees(122, 20, 153, 45);
navoptions.enableCompass = true;
navoptions.enableZoomControls = true;
navoptions.enableDistanceLegend = true;
navoptions.enableCompassOuterRing = true;
CesiumNavigation(viewer, navoptions);
///// Navigation ///

 


/// データがない場合のエラー表示 ////////////

function getCSVcheck(no,year) {
  var req = new XMLHttpRequest();
  req.open('get', '/csv/kokudo/'+no+'_2次メッシュ'+year+'.csv', true);
  req.send(null);
  
    req.onload = function() {
  if(
    req.getResponseHeader("content-Length") == null
    ){
    console.log(req.getResponseHeader("content-Length"));
    alert("このテーマは、国土全体の表示ができません。")
  }
  console.log(req.getResponseHeader("content-Length"));
  };
}
getCSVcheck(this.props.no,this.props.year5);

//////////// データがない場合のエラー表示 ///






/// KML生成準備 ////////////

var kmlpre = [];
var kml1_colora = this.props.kml1_color;
var kml2_colora = this.props.kml2_color;
var kml3_colora = this.props.kml3_color;
var kml4_colora = this.props.kml4_color;

var kml1_color = kml1_colora.slice(5,7)+kml1_colora.slice(3,5)+kml1_colora.slice(1,3);
var kml2_color = kml2_colora.slice(5,7)+kml2_colora.slice(3,5)+kml2_colora.slice(1,3);
var kml3_color = kml3_colora.slice(5,7)+kml3_colora.slice(3,5)+kml3_colora.slice(1,3);
var kml4_color = kml4_colora.slice(5,7)+kml4_colora.slice(3,5)+kml4_colora.slice(1,3);

  
/// /// １.2列目の閾値で色分け /////////////////////////
  function mesh3rd2kml1( meshcode3rd , h, c){
    var strMeshcoe = String(meshcode3rd);
    var p = parseInt(strMeshcoe.slice(0,2));
    var u = parseInt(strMeshcoe.slice(2,4));
    var q = parseInt(strMeshcoe.slice(4,5));
    var v = parseInt(strMeshcoe.slice(5,6));
    // var r = parseInt(strMeshcoe.slice(6,7));
    // var w = parseInt(strMeshcoe.slice(7,8));
    var lat = p / 1.5 + q / 12
    //  + r / 120;
    var lon = u + 100 + v * 0.125
    //  + w * 0.0125;
    var lon2 = lon+0.125;
    var lat2 = lat+0.08333333;
    var latc = (lat+lat2)/2;
    var lonc = (lon+lon2)/2;
    var hdis = h*ratio+5;

      var colorkml1;
      if (h >= shikii1) {
        colorkml1 = "1";
        // color1 = "#67ADDF";
      } else if (h >= shikii2) {
        colorkml1 = "2";
      } else if (h >= shikii3) {
        colorkml1 = "3";
      } else {
        colorkml1 = "4";
      }

      var ans_kml = `
      <Placemark>
      <name>メッシュ番号：`+strMeshcoe+`</name>
      <description>高さの値：`+h+`</description>
      <styleUrl>#PolyCol`+colorkml1+`</styleUrl>
      <Polygon>
      <altitudeMode>relativeToGround</altitudeMode>
      <extrude>1</extrude>
      <outerBoundaryIs>
      <LinearRing>
      <coordinates>
      `
      +lon+","+lat+","+hdis+`
     
      `
      +lon2+","+lat+","+hdis+`
     
      `
      +lon2+","+lat2+","+hdis+`
      
      `
      +lon+","+lat2+","+hdis+`
      
      `
      +lon+","+lat+","+hdis
      +
      
      `
      </coordinates>
      </LinearRing>
      </outerBoundaryIs>
      </Polygon>
<LookAt>
      <latitude>`+latc+`</latitude>
      <longitude>`+lonc+`</longitude>
      <altitude>0</altitude>
      <range>1000</range>
      <tilt>0</tilt>
      <heading>0</heading>
      <altitudeMode>relativeToGround</altitudeMode>
    </LookAt>
      </Placemark>
      `;

      return ans_kml ;
  }
  /// ///////////////////////// １.2列目の閾値で色分け ///
  /// /// 2.3列目の値で色分け /////////////////////////
    function mesh3rd2kml2( meshcode3rd , h, c){
      var strMeshcoe = String(meshcode3rd);
      var p = parseInt(strMeshcoe.slice(0,2));
      var u = parseInt(strMeshcoe.slice(2,4));
      var q = parseInt(strMeshcoe.slice(4,5));
      var v = parseInt(strMeshcoe.slice(5,6));
    // var r = parseInt(strMeshcoe.slice(6,7));
    // var w = parseInt(strMeshcoe.slice(7,8));
    var lat = p / 1.5 + q / 12
    //  + r / 120;
    var lon = u + 100 + v * 0.125
    //  + w * 0.0125;
    var lon2 = lon+0.125;
    var lat2 = lat+0.08333333;
      var latc = (lat+lat2)/2;
      var lonc = (lon+lon2)/2;
        var hdis = h*ratio+5;

        var colorkml1;
        if (c == 3) {
          colorkml1 = "1";
          // color1 = "#67ADDF";
        } else if (c == 2) {
          colorkml1 = "2";
        } else if (c == 1) {
          colorkml1 = "3";
        } else {
          colorkml1 = "4";
        }
    

        var ans_kml = `
        <Placemark>
        <name>メッシュ番号：`+strMeshcoe+`</name>
        <description>高さの値：`+h+`</description>
          <styleUrl>#PolyCol`+colorkml1+`</styleUrl>
        <Polygon>
        <altitudeMode>relativeToGround</altitudeMode>
        <extrude>1</extrude>
        <outerBoundaryIs>
        <LinearRing>
        <coordinates>
        `
        +lon+","+lat+","+hdis+`
       
        `
        +lon2+","+lat+","+hdis+`
       
        `
        +lon2+","+lat2+","+hdis+`
        
        `
        +lon+","+lat2+","+hdis+`
        
        `
        +lon+","+lat+","+hdis
        +
        
        `
        </coordinates>
          </LinearRing>
        </outerBoundaryIs>
        </Polygon>
<LookAt>
      <latitude>`+latc+`</latitude>
      <longitude>`+lonc+`</longitude>
      <altitude>0</altitude>
      <range>1000</range>
      <tilt>0</tilt>
      <heading>0</heading>
      <altitudeMode>relativeToGround</altitudeMode>
    </LookAt>
  
        </Placemark>
        `;
  
        return ans_kml ;
    }
  
  /// ///////////////////////// 2.3列目の値で色分け ///
  
  /// /// 3.2列目の閾値で色分け /////////////////////////
    function mesh3rd2kml3( meshcode3rd , h, c){
      var strMeshcoe = String(meshcode3rd);
      var p = parseInt(strMeshcoe.slice(0,2));
      var u = parseInt(strMeshcoe.slice(2,4));
      var q = parseInt(strMeshcoe.slice(4,5));
      var v = parseInt(strMeshcoe.slice(5,6));
    // var r = parseInt(strMeshcoe.slice(6,7));
    // var w = parseInt(strMeshcoe.slice(7,8));
    var lat = p / 1.5 + q / 12
    //  + r / 120;
    var lon = u + 100 + v * 0.125
    //  + w * 0.0125;
    var lon2 = lon+0.125;
    var lat2 = lat+0.08333333;
      var latc = (lat+lat2)/2;
      var lonc = (lon+lon2)/2;
        var hdis = h*ratio+5;

        var colorkml1;
        if (c >= shikii1) {
          colorkml1 = "1";
          // color1 = "#67ADDF";
        } else if (c >= shikii2) {
          colorkml1 = "2";
        } else if (c >= shikii3) {
          colorkml1 = "3";
        } else {
          colorkml1 = "4";
        }
    
      var ans_csv = {
              // parent: data1995,
              // id:meshcode3rd ,
              // description : "<p>"+description_text+"</p>",
              // rectangle : {
                  coordinates : Rectangle.fromDegrees( lon , lat , lon+0.125 , lat+0.08333333 ),
                  height : 0,
                  extrudedHeight : h*ratio,
                  // material : Color.fromRgba( color_gbra )
                  outline : true,
                  outlineColor : "white",
                  outlineWidth : 1,
                  // shadows : ShadowMode.ENABLED
              // }
              
          };

      var ans_kml = `
      <Placemark>
      <name>メッシュ番号：`+strMeshcoe+`</name>
      <description>高さの値：`+h+`</description>
      <styleUrl>#PolyCol`+colorkml1+`</styleUrl>
      <Polygon>
      <altitudeMode>relativeToGround</altitudeMode>
      <extrude>1</extrude>
      <outerBoundaryIs>
      <LinearRing>
      <coordinates>
      `
      +lon+","+lat+","+hdis+`
     
      `
      +lon2+","+lat+","+hdis+`
     
      `
      +lon2+","+lat2+","+hdis+`
      
      `
      +lon+","+lat2+","+hdis+`
      
      `
      +lon+","+lat+","+hdis
      +
      
      `
      </coordinates>
      </LinearRing>
      </outerBoundaryIs>
      </Polygon>
<LookAt>
      <latitude>`+latc+`</latitude>
      <longitude>`+lonc+`</longitude>
      <altitude>0</altitude>
      <range>1000</range>
      <tilt>0</tilt>
      <heading>0</heading>
      <altitudeMode>relativeToGround</altitudeMode>
    </LookAt>

      </Placemark>
      `;

      return ans_kml ;
    }
    /// ///////////////////////// 3.2列目の閾値で色分け ///
  
  
  function getkml(no,year,dataname) {
    var kmlprepre=[];
    var req = new XMLHttpRequest();
    req.open('get', '/csv/kokudo/'+no+'_2次メッシュ'+year+'.csv', true);
    req.send(null);
    req.onload = function() {
      // setCSV(req.responseText);
      //ここでlocalStorageへ
      var dataArr;
      // var r = document.getElementById('r');
      var tmp = req.responseText.split('\n');
      // console.log(tmp.length);
  
      // var firstview = viewer.entities.add(mesh3rdRect1(tmp[0].substr(0,6),tmp[0].substr(7))).then(
      //   viewer.flyTo(viewer.entities)
      // )
      // .catch(failureCallback);

      if (csvmode == "color_shikii") {
  
      for(let i = 0, len = tmp.length; i< len ; i++){
        dataArr = tmp[i].split(',');
        var minmin = Number(min);
        if (dataArr[1]>minmin) {
          // viewer.entities.add(
          //   {
          //     parent: dataname,
          //     // id:meshcode3rd ,
          //     // description : "<p>"+description_text+"</p>",
          //     rectangle: mesh3rdRect3(dataArr[0],dataArr[1],dataArr[2])
          //   }
          kmlprepre[i] = mesh3rd2kml3(dataArr[0],dataArr[1],dataArr[2])




          // );
        // viewer.entities.add(mesh3rdRect1(tmp[i].substr(0,6),tmp[i].substr(7)));
        // if(tmp[0]){
        // }
        }
      }

    } else if(shikii1 !== 0){
  
        for(let i = 0, len = tmp.length; i< len ; i++){
          dataArr = tmp[i].split(',');
          var minmin = Number(min);
          if (dataArr[1]>minmin) {

            kmlprepre[i] = mesh3rd2kml1(dataArr[0],dataArr[1],dataArr[2])
  
            // viewer.entities.add(
            //   {
            //     parent: dataname,
            //     // id:meshcode3rd ,
            //     // description : "<p>"+description_text+"</p>",
            //     rectangle: mesh3rdRect1(dataArr[0],dataArr[1],dataArr[2])
            //   }
            // );
          // viewer.entities.add(mesh3rdRect1(tmp[i].substr(0,6),tmp[i].substr(7)));
          // if(tmp[0]){
          // }
          }
        }
    
  
  
    } else {
  
      for(let i = 0, len = tmp.length; i< len ; i++){
        dataArr = tmp[i].split(',');
        var minmin = Number(min);
        if (dataArr[1]>minmin) {
          kmlprepre[i] = mesh3rd2kml2(dataArr[0],dataArr[1],dataArr[2])

          // viewer.entities.add(
          //   {
          //     parent: dataname,
          //     // id:meshcode3rd ,
          //     // description : "<p>"+description_text+"</p>",
          //     rectangle: mesh3rdRect2(dataArr[0],dataArr[1],dataArr[2])
          //   }
          // );
        // viewer.entities.add(mesh3rdRect1(tmp[i].substr(0,6),tmp[i].substr(7)));
        // if(tmp[0]){
        // }
        }
      }
    };
    
  };
  return kmlprepre;

  };


  
  var data1995kml = getkml(this.props.no,this.props.year1, data1995);
  var data2000kml = getkml(this.props.no,this.props.year2, data2000);
  var data2005kml = getkml(this.props.no,this.props.year3, data2005);
  var data2010kml = getkml(this.props.no,this.props.year4, data2010);
  var data2015kml = getkml(this.props.no,this.props.year5, data2015);

  var year1kml = this.props.year1;
  var year2kml = this.props.year2;
  var year3kml = this.props.year3;
  var year4kml = this.props.year4;
  var year5kml = this.props.year5;
  var year5_6kml = Number(year5kml) - Number(2005);
  var year6kml = Number(year5kml) + year5_6kml;
  var year7kml = Number(year6kml) + (Number(year5kml) - Number(year1kml))/4;

   var year1_b = year1kml+'-04-01';
  var year2_b = year2kml+'-04-01';
  var year3_b = year3kml+'-04-01';
  var year4_b = year4kml+'-04-01';
  var year5_b = year5kml+'-04-01';
  
  var year1_e = year2kml+'-04-01';
  var year2_e = year3kml+'-04-01';
  var year3_e = year4kml+'-04-01';
  var year4_e = year5kml+'-04-01';
  var year5_e = year6kml+'-04-01';
  

 if(year1kml>0){ var year1_begin = year1_b } else {  var year1_begin = year5_b };
  if(year2kml>0){ var year2_begin = year2_b } else {  var year2_begin = year5_b };
  if(year3kml>0){ var year3_begin = year3_b } else {  var year3_begin = year5_b };
  if(year4kml>0){ var year4_begin = year4_b } else {  var year4_begin = year5_b };
  if(year5kml>0){ var year5_begin = year5_b } else {  var year5_begin = year5_b };

  if(year2kml>0){ var year1_end = year1_e } else { var year1_end = year5_begin };
  if(year3kml>0){ var year2_end = year2_e } else { var year2_end = year5_begin };
  if(year4kml>0){ var year3_end = year3_e } else { var year3_end = year5_begin };
  if(year5kml>0){ var year4_end = year4_e } else { var year4_end = year5_begin };
  if(year6kml>0){ var year5_end = year5_e } else { var year5_end = year5_begin };

  var no = this.props.no;
  var title = this.props.title;
  var cityname = this.props.cityname;


////////////// KML生成準備 ///
/// KML生成 /////
  var kmlfilename = (cityname+"_"+title+".kml");



  const button1 = document.getElementById('kmldownloadbutton');
  button1.addEventListener('click', function (evt) {
  evt.preventDefault();

  // var kmlll = "Hello"+", KML! KML!"+min+ratio;
  var kmlll = `<?xml version="1.0" encoding="UTF-8"?>
  <kml xmlns="http://www.opengis.net/kml/2.2">
  <Document>



  
  <name>`+cityname+"_"+title+`</name>
  
  <Style id="PolyCol1">
  <LineStyle><color>C8FFFFFF</color><width>1</width></LineStyle>
  <PolyStyle>
  <color>`+"c8"+kml1_color+`</color>
  <fill>1</fill><outline>1</outline></PolyStyle>
  </Style>

  <Style id="PolyCol2">
  <LineStyle><color>C8FFFFFF</color><width>1</width></LineStyle>
  <PolyStyle>
  <color>`+"c8"+kml2_color+`</color>
  <fill>1</fill><outline>1</outline></PolyStyle>
  </Style>

  <Style id="PolyCol3">
  <LineStyle><color>C8FFFFFF</color><width>1</width></LineStyle>
  <PolyStyle>
  <color>`+"c8"+kml3_color+`</color>
  <fill>1</fill><outline>1</outline></PolyStyle>
  </Style>

  <Style id="PolyCol4">
  <LineStyle><color>C8FFFFFF</color><width>1</width></LineStyle>
  <PolyStyle>
  <color>`+"c8"+kml4_color+`</color>
  <fill>1</fill><outline>1</outline></PolyStyle>
  </Style>

  <LookAt>
	<longitude>134</longitude>
	<latitude>25</latitude>
	<altitude>1500000</altitude>
	<range>0.1</range>
	<tilt>28</tilt>
	<heading>0</heading>
	<altitudeMode>relativeToGround</altitudeMode>
</LookAt>

  <Folder>
  <name>`+year1kml+`</name>
  <TimeSpan>
  <begin>`+year1_begin+`</begin>
  <end>`+year1_end+`</end>

  </TimeSpan>

  <ScreenOverlay>
  <name>凡例表示</name>
  <visibility>1</visibility>
  <Icon>
  <href>https://www.geograph.teikokushoin.co.jp/hanrei_kokudo/`+no+`_`+year1kml+`.png</href>
  </Icon>
  <overlayXY x="0" y="1" xunits="fraction" yunits="fraction"/>
  <screenXY x="0" y="1" xunits="fraction" yunits="fraction"/>
  <rotationXY x="0" y="0" xunits="fraction" yunits="fraction"/>
  <size x="0" y="0" xunits="fraction" yunits="fraction"/>
  </ScreenOverlay>

   `+data1995kml+`

   </Folder>


     <Folder>
  <name>`+year2kml+`</name>
  <TimeSpan>
  <begin>`+year2_begin+`</begin>
  <end>`+year2_end+`</end>
  </TimeSpan>
  <ScreenOverlay>
  <name>凡例表示</name>
  <visibility>1</visibility>
  <Icon>
  <href>https://www.geograph.teikokushoin.co.jp/hanrei_kokudo/`+no+`_`+year2kml+`.png</href>
  </Icon>
  <overlayXY x="0" y="1" xunits="fraction" yunits="fraction"/>
  <screenXY x="0" y="1" xunits="fraction" yunits="fraction"/>
  <rotationXY x="0" y="0" xunits="fraction" yunits="fraction"/>
  <size x="0" y="0" xunits="fraction" yunits="fraction"/>
  </ScreenOverlay>

   `+data2000kml+`
  </Folder>
  <Folder>
  <name>`+year3kml+`</name>
  <TimeSpan>
  <begin>`+year3_begin+`</begin>
  <end>`+year3_end+`</end>
  </TimeSpan>
  <ScreenOverlay>
  <name>凡例表示</name>
  <visibility>1</visibility>
  <Icon>
  <href>https://www.geograph.teikokushoin.co.jp/hanrei_kokudo/`+no+`_`+year3kml+`.png</href>
  </Icon>
  <overlayXY x="0" y="1" xunits="fraction" yunits="fraction"/>
  <screenXY x="0" y="1" xunits="fraction" yunits="fraction"/>
  <rotationXY x="0" y="0" xunits="fraction" yunits="fraction"/>
  <size x="0" y="0" xunits="fraction" yunits="fraction"/>
  </ScreenOverlay>

   `+data2005kml+`
  </Folder>
  <Folder>
  <name>`+year4kml+`</name>
  <TimeSpan>
  <begin>`+year4_begin+`</begin>
  <end>`+year4_end+`</end>
  </TimeSpan>
  <ScreenOverlay>
  <name>凡例表示</name>
  <visibility>1</visibility>
  <Icon>
  <href>https://www.geograph.teikokushoin.co.jp/hanrei_kokudo/`+no+`_`+year4kml+`.png</href>
  </Icon>
  <overlayXY x="0" y="1" xunits="fraction" yunits="fraction"/>
  <screenXY x="0" y="1" xunits="fraction" yunits="fraction"/>
  <rotationXY x="0" y="0" xunits="fraction" yunits="fraction"/>
  <size x="0" y="0" xunits="fraction" yunits="fraction"/>
  </ScreenOverlay>

   `+data2010kml+`
  </Folder>

  <Folder>
  <name>`+year5kml+`</name>
  <TimeSpan>
  <begin>`+year5_begin+`</begin>
  <end>`+year5_end+`</end>
  </TimeSpan>
  <ScreenOverlay>
  <name>凡例表示</name>
  <visibility>1</visibility>
  <Icon>
  <href>https://www.geograph.teikokushoin.co.jp/hanrei_kokudo/`+no+`_`+year5kml+`.png</href>
  </Icon>
  <overlayXY x="0" y="1" xunits="fraction" yunits="fraction"/>
  <screenXY x="0" y="1" xunits="fraction" yunits="fraction"/>
  <rotationXY x="0" y="0" xunits="fraction" yunits="fraction"/>
  <size x="0" y="0" xunits="fraction" yunits="fraction"/>
  </ScreenOverlay>

   `+data2015kml+`
  </Folder>




  </Document>
  </kml>
  `;

  const blob = new Blob([kmlll], {type: 'application/vnd.google-earth.kml+xml kml'});
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.download = kmlfilename;
  a.href = url;
  a.click();
  a.remove();
  URL.revokeObjectURL(url);
}


  // viewer.flyTo(dataSource1, new HeadingPitchRange(0, -1, 50000));

          //   let currentvalue = document.getElementById('rotate').value;
          //   if (currentvalue == "回転させる") {
          //       document.getElementById("rotate").value="Stop"
          //       viewer.clock.onTick.addEventListener(function(clock) {
          //       viewer.scene.camera.rotateRight(-0.005);
          //       });
          //   };
          //   viewer.flyTo(viewer.entities, new HeadingPitchRange(0, -1, 50000));

          // }, false);
///// KML生成 ///




  )
  }
 



  
//   componentDidUpdate() {

// /// KMLデータ準備 ///////
//     var dataSource1 = new KmlDataSource.load(this.props.kml_kml,{
//       camera: this.viewer.scene.camera,
//       canvas: this.viewer.scene.canvas,
//     });
// /////// KMLデータ準備 ///

// /// KMLデータ読み込み ///////
// this.viewer.dataSources.add(dataSource1).show = true;
// this.viewer.zoomTo(dataSource1
//   // , new HeadingPitchRange(0, -1, 2000000)
//   );
// /////// KMLデータ読み込み ///

//   }





  // componentWillUnmount() {
  //   this.viewer.destroy();
  // }


}

export default CesiumContainer

